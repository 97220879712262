/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";

.tooltip-label {
    border-left: 5px solid #556ee6;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 3px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
}

.tooltip-label-test-send {
    border-left: 5px solid #50A5F1 ;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 3px;
    padding-bottom: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
}
.tooltip-content {
    padding-left: 10px;
}

.tooltip-item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.tooltip-item i {
    min-width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
}