// 
// _badge.scss
// 


.badge {
  &[href] {

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {

    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
    &.bg-#{$color} {
      &[href] {

        &:hover,
        &:focus {
          background-color: darken($value, 4%) !important;
        }
      }
    }
  }

  .badge {
    &.bg-light {
      color: $body-color;

      &[href] {

        &:hover,
        &:focus {
          color: $body-color;
        }
      }
    }
  }

  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }

}

.rounded-pill {
  padding-right: .6em;
  padding-left: .6em;
}

// Dark badge

.badge.bg-dark {
  color: $light;
}

.badge-draft-soft,
.badge-canceled-soft,
.badge-close-soft {
  color: #9ea7ad;
  background-color: rgba(158, 167, 173, 0.18);
}

.badge-draft,
.badge-canceled,
.badge-close {
  color: #74788d;
  background-color: rgba(116, 120, 141, 0.18);
}

.icon-badge-draft,
.icon-badge-canceled,
.icon-badge-close {
  color: rgba(116, 120, 141, 0.18) !important;
}

.badge-submitted-soft,
.badge-collected-soft,
.badge-delivered-soft,
.badge-received-soft,
.badge-inprocess-soft,
.badge-open-soft,
.badge-upl-soft,
.badge-exp-soft {
  color: #2dccff;
  background-color: rgba(45, 204, 255, 0.18);
}

.badge-delivered,
.badge-received,
.badge-open,
.badge-upl,
.badge-exp {
  color: #fff;
  background-color: #50A5F1
}

.badge-submitted{
  color: #fff;
  background-color: #00C8F8
}

.icon-badge-submitted,
.icon-badge-delivered,
.icon-badge-received,
.icon-badge-open,
.icon-badge-upl,
.icon-badge-exp {
  color: #50A5F1 !important
}

.badge-delivered{
  color: #fff;
  background-color: #6E7623
}

.badge-received {
  color: #fff;
  background-color: #35439E
}

.badge-inprocess {
  color: #fff;
  background-color: #75A9F9
}

.icon-badge-delivered,
.icon-badge-received {
  color: #35439E !important
}

.icon-badge-collected {
  color: #B0D24D !important
}

.icon-badge-inprocess {
  color: #75A9F9 !important
}


.badge-onhold-soft,.badge-transfrerred-soft,
.badge-rev-soft {
  color: #f1b44c;
  background-color: rgba(241, 180, 76, 0.18);
}

.badge-transfrerred,
.badge-rev {
  color: #000;
  background-color: #ffb302;
}

.badge-onhold {
  color: #fff;
  background-color: #D68227;
}

.badge-danger-soft,
.badge-del-soft,
.badge-ex00-soft,
.badge-ex01-soft,
.badge-ex02-soft,
.badge-ex03-soft,
.badge-ex04-soft,
.badge-ex05-soft,
.badge-e00-soft,
.badge-e01-soft,
.badge-e02-soft,
.badge-e03-soft,
.badge-e04-soft,
.badge-e05-soft,.badge-sent-soft {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.18);
}

.badge-danger,
.badge-del,
.badge-ex00,
.badge-ex01,
.badge-ex02,
.badge-ex03,
.badge-ex04,
.badge-ex05,
.badge-e00,
.badge-e01,
.badge-e02,
.badge-e03,
.badge-e04,.badge-sent,
.badge-e05 {
  color: #fff;
  background-color: #dc3545;
}

.icon-badge-danger,
.icon-badge-del,
.icon-badge-ex00,
.icon-badge-ex01,
.icon-badge-ex02,
.icon-badge-ex03,
.icon-badge-ex04,
.icon-badge-ex05,
.icon-badge-e00,
.icon-badge-e01,
.icon-badge-e02,
.icon-badge-e03,
.icon-badge-e04,
.icon-badge-e05 {
  color: #dc3545 !important;
}

.badge-signed-soft,
.badge-released-soft,
.badge-approved-soft,
.badge-reported-soft,
.badge-val-soft {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}

.badge-confirmed-soft{
  color: #FFB253;
  background-color: rgba(52, 195, 143, 0.18);
}

.badge-completed-soft{
  color: #5E9CB3;
  background-color: rgba(52, 195, 143, 0.18);
}

.badge-validated-soft{
  color: #F4EB49;
  background-color: rgba(52, 195, 143, 0.18);
}

.badge-signed,
.badge-approved,
.badge-val {
  color: #fff;
  background-color: #34c38f;
}

.badge-released {
  color: #fff;
  background-color: #34C38F;
}

.badge-validated{
  color: #000;
  background-color: #F4EB49;
}

.badge-confirmed{
  color: #000;
  background-color: #FFB253;
}

.badge-completed{
  color: #fff;
  background-color: #5E9CB3;
}

.badge-reported {
  background-color: #0073ff !important;
  color: white;
}

.badge-width {
  min-width: 80px;
  max-width: 95px;
}

.badge-rewidth {
  min-width: 20px;
}

.icon-badge-validated,
.icon-badge-released,
.icon-badge-approved,
.icon-badge-reported,
.icon-badge-val {
  color: #34c38f !important;
}

.icon-badge-completed{
  color: #5E9CB3 !important;
}

.icon-badge-confirmed{
  color: #FFB253 !important;
}

.badge-dark {
  color: #f7f7f7;
  background-color: #333;
}

.badge-collected {
  color: #fff;
  background-color: #B0D24D !important
}