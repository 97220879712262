.btn-soft-copy {
    background-color: hsla(0,86%,69%,.1);
    border-color: transparent;
    color: #556ee6;
    transition: all .4s ease;
}  

.btn-soft-info {
    background-color: hsla(0,86%,69%,.1);
    border-color: transparent;
    color: #50a5f1;
    transition: all .4s ease;
} 

.btn-soft-danger {
    background-color: hsla(0,86%,69%,.1);
    border-color: transparent;
    color: #f46a6a;
    transition: all .4s ease;
}
