.picture-container {
    position: relative;
    cursor: pointer;
    text-align: center;
}

.picture {
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #CCCCCC;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    transition: border-color 0.2s;
    position: relative;
}

.picture img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
}

.picture:hover,
.content.ct-wizard-green .picture:hover {
    border-color: #2ca8ff;
}

.content.ct-wizard-blue .picture:hover {
    border-color: #3472f7;
}

.content.ct-wizard-orange .picture:hover {
    border-color: #ff9500;
}

.content.ct-wizard-red .picture:hover {
    border-color: #ff3b30;
}

.picture input[type="file"] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.picture-src {
    width: 100%;
    height: auto;
}
.picture-container {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
  }
.picture-container .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }
  .picture-container .avatar-edit button {
    display: none;
  }
  .picture-container .avatar-edit button + label {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-bottom: 0px;
    margin-right: 35px;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .picture-container .avatar-edit button + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .picture-container .avatar-edit button + label:after {
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }

  .custom-input {
    position: relative;
  
    .form-control {
      padding-right: 40px;
    }
  
    Button {
      position: absolute;
      width: 10px;
      line-height: 5px;
      top: 68%;
      right: 10px;
      transform: translateY(-40%);
      cursor: pointer;
      box-sizing: content-box;
    }
  }

  @media (min-width: 986px) {
    .custom-col-margin {
      margin-left: 40px;
    }
    .custom-row {
      margin-left: 15px;
    }
  }
  
  @media (max-width: 985px) {
    .custom-col-margin {
      margin-left: 0;
    }
    .custom-row {
      margin-left: 0;
    }
  }