.custom-input-group {
    position: relative;
  
    .form-control {
      padding-right: 40px;
    }
  
    Button {
      position: absolute;
      width: 10px;
      line-height: 5px;
      top: 40%;
      right: 10px;
      transform: translateY(-40%);
      cursor: pointer;
      box-sizing: content-box;
    }
  }

  .signature-container {
    position: relative;
    cursor: pointer;
}

.signature {
    width: 200px;
    height: 100px;
    background-color: #999999;
    border: 4px solid #CCCCCC;
    color: #FFFFFF;
    margin: 0 auto;
    overflow: hidden;
    transition: border-color 0.2s;
    position: relative;
}

.signature img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.signature:hover,
.content.ct-wizard-green .signature:hover {
    border-color: #2ca8ff;
}

.content.ct-wizard-blue .signature:hover {
    border-color: #3472f7;
}

.content.ct-wizard-orange .signature:hover {
    border-color: #ff9500;
}

.content.ct-wizard-red .signature:hover {
    border-color: #ff3b30;
}

.signature input[type="file"] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.signature-src {
    width: 100%;
    height: auto;
}
.signature-container {
    position: relative;
    max-width: 205px;
    // margin: 50px auto;
  }
.signature-container .signature-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }
  .signature-container .avatar-signature button {
    display: none;
  }
  .signature-container .avatar-signature button + label {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-bottom: 0px;
    margin-right: 35px;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .signature-container .avatar-signature button + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .signature-container .avatar-signature button + label:after {
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }

  .custom-input {
    position: relative;
  
    .form-control {
      padding-right: 40px;
    }
  
    Button {
      position: absolute;
      width: 10px;
      line-height: 5px;
      top: 68%;
      right: 10px;
      transform: translateY(-40%);
      cursor: pointer;
      box-sizing: content-box;
    }
  }